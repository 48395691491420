import * as amplitude from '@amplitude/analytics-browser'

import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

type App = 'employee-benefits-spa'

type DashboardTrackId = 'dashboard'
type DashboardTrackIds =
  | 'coming-soon'
  | 'group-health'
  | 'manual-deduction'
  | 'legacy.connect-existing'
  | 'legacy.view-existing'
  | 'vestwell'
  | 'payroll-pro-upsell.request-demo'
  | 'payroll-pro-upsell.learn-more'

export const trackDashboard = (actionTrackId: DashboardTrackIds) => {
  track(`employee-benefits-spa.dashboard.${actionTrackId}`)
}

type ConsentTrackId = 'consent'
type ConsentTrackIds =
  | 'error-modal.close'
  | 'error-modal.retry'
  | 'close'
  | 'confirm'
  | 'privacy-statement'
  | 'simply-insured-email'
  | 'simply-insured-phone'
  | 'simply-insured-insurance-licenses'
  | 'toast-insurance-licenses'

export const trackConsent = (actionTrackId: ConsentTrackIds) => {
  track(`employee-benefits-spa.consent.${actionTrackId}`)
}

type DeductionTrackId = 'deduction'
type DeductionTrackIds = 'close' | 'download' | 'chat'

export const trackDeduction = (actionTrackId: DeductionTrackIds) => {
  track(`employee-benefits-spa.deduction.${actionTrackId}`)
}

type GroupHealthTrackId = 'group-health'
type GroupHealthTrackIds = 'new-plan-click' | 'broker-of-record-click'

export const trackGroupHealth = (actionTrackId: GroupHealthTrackIds) => {
  track(`employee-benefits-spa.group-health.${actionTrackId}`)
}

type SimplyInsuredTrackId = 'simply-insured'
type SimplyInsuredTrackIds = 'request-close' | 'close' | 'cancel-close'

export const trackSimplyInsured = (actionTrackId: SimplyInsuredTrackIds) => {
  track(`employee-benefits-spa.simply-insured.${actionTrackId}`)
}

export type TrackId =
  | `${App}.${DashboardTrackId}.${DashboardTrackIds}`
  | `${App}.${ConsentTrackId}.${ConsentTrackIds}`
  | `${App}.${DeductionTrackId}.${DeductionTrackIds}`
  | `${App}.${GroupHealthTrackId}.${GroupHealthTrackIds}`
  | `${App}.${SimplyInsuredTrackId}.${SimplyInsuredTrackIds}`

const INSTANCE_NAME = 'employee-compensation'
const PROD_API_KEY = 'ca4a52d64a0d8a59d4963f57278d1201'
const PREPROD_API_KEY = '55fa710e722cf51b16ab684cf4d0adff'

let instance: amplitude.Types.BrowserClient

/**
 * creates a new instance of amplitude to track against
 * props: based on BanquetProps
 */
export async function loadTracking() {
  const env = getCurrentEnvironment()
  const newInstance = amplitude.createInstance()

  newInstance.init(
    env === ToastEnvironment.PROD ? PROD_API_KEY : PREPROD_API_KEY,
    {
      instanceName: INSTANCE_NAME,
      autocapture: false,
      identityStorage: 'localStorage'
    }
  )
  instance = newInstance
}

const track = (trackId: TrackId) => {
  instance.track({
    event_type: trackId
  })
}
