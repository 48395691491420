import * as React from 'react'
import { useHref, useNavigate } from 'react-router-dom'
import {
  EmployeesDisplayIcon,
  HealthBenefitsDisplayIcon,
  HealthInsuranceDisplayIcon,
  LaunchIcon
} from '@toasttab/buffet-pui-icons'
import { CommitmentAccountabilityIllustration } from '@toasttab/buffet-pui-illustrations'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { PayrollDashboardCard } from '@toasttab/buffet-pui-payroll-dashboard-card'
import { PayrollUpsellPanel } from '@toasttab/buffet-pui-payroll-upsell-panel'
import { t } from '@local/translations'
import { useCustomer, useUser } from '@local/contexts'
import { NotAuthorizedState } from '@local/not-authorized-state'
import { trackDashboard } from '@local/track'
import { ConsentModal } from '../third-part-data-sharing-consent-modal'
import { DeductionModal } from '../deduction-modal'
import { VestwellDisplayIcon } from './DisplayIcon/components'

export type DashboardPageProps = {
  isDeductionModalOpen?: boolean
}

export const DashboardPage: React.FunctionComponent<DashboardPageProps> = ({
  isDeductionModalOpen = false
}) => {
  const hasPermission = useUser().isPureUserHRPlusOrAbove
  const hasSimplyInsuredOption = useCustomer().hasSimplyInsuredBenefitsOption
  const hasBenefitsBrokerageAdministration =
    useCustomer().hasBenefitsAdministration
  const hasLegacyBenefitsModule = useCustomer().hasLegacyBenefitsModule
  const hasVestwellRetirementPlanOption =
    useCustomer().hasVestwellRetirementPlanOption
  const hasEntitlementOverrideOption =
    useCustomer().hasEntitlementOverrideOption
  const showGroupHealthBenefits =
    hasEntitlementOverrideOption || hasBenefitsBrokerageAdministration

  return !hasPermission ? (
    <NotAuthorizedState />
  ) : (
    <div className='grid gap-y-6'>
      {!hasSimplyInsuredOption && showGroupHealthBenefits ? (
        <div className='grid gap-6 md:grid-cols-2 w-fit'>
          <DeductionsCard isDeductionModalOpen={isDeductionModalOpen} />
          <HealthBenefitsComingSoonCard />
          {hasLegacyBenefitsModule && <LegacyBenefitsNoSimplyInsuredCard />}
          {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
        </div>
      ) : !hasSimplyInsuredOption && !showGroupHealthBenefits ? (
        <div className='grid gap-6 md:grid-cols-2 w-fit'>
          {hasLegacyBenefitsModule && <LegacyBenefitsNoSimplyInsuredCard />}
          {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
          <DeductionsCard isDeductionModalOpen={isDeductionModalOpen} />
        </div>
      ) : showGroupHealthBenefits ? (
        <div className='grid gap-6 md:grid-cols-2 w-fit'>
          <HealthBenefitsCard />
          <DeductionsCard isDeductionModalOpen={isDeductionModalOpen} />
          {hasLegacyBenefitsModule && <LegacyBenefitsCard />}
          {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
        </div>
      ) : (
        <div className='grid gap-y-6 md:gap-y-8'>
          <PayrollProUpsellPanel />
          <DividingLine className='mx-4 md:mx-0' />
          <div className='grid gap-6 md:grid-cols-2 w-fit'>
            <DeductionsCard isDeductionModalOpen={isDeductionModalOpen} />
            {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
          </div>
        </div>
      )}
      <FootnoteSection />
    </div>
  )
}

/**
 * Health benefits cards for customers without the SimplyInsured feature flag turned on
 * Allows customers to sign up on the waitlist for SimplyInsured
 */
const HealthBenefitsComingSoonCard: React.FunctionComponent = () => {
  return (
    <PayrollDashboardCard
      badgeLabel={t('dashboard.group-health.coming-soon.badge')}
      description={t('dashboard.group-health.coming-soon.description')}
      icon={<HealthInsuranceDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.group-health.subtitle')}
      title={t('dashboard.group-health.title')}
      linkAction={{
        label: t('dashboard.group-health.coming-soon.link'),
        href: 'https://www.toasttab.com/customers/shop/test-kitchen/shbi',
        newTab: true,
        onClick: () => trackDashboard('coming-soon')
      }}
    />
  )
}

/**
 * Health benefits card for customers with access to SimplyInsured
 * Navigates users to the group health benefits page to manage health benefit options
 */
const HealthBenefitsCard: React.FunctionComponent = () => {
  const href = useHref('group-health')
  return (
    <PayrollDashboardCard
      badgeLabel={t('dashboard.group-health.badge')}
      description={t('dashboard.group-health.description')}
      icon={<HealthInsuranceDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.group-health.subtitle')}
      title={t('dashboard.group-health.title')}
      linkAction={{
        label: t('dashboard.group-health.link'),
        href: href,
        onClick: () => trackDashboard('group-health')
      }}
    />
  )
}

/**
 * Access to add a manual deduction to payroll
 */
const DeductionsCard: React.FunctionComponent<{
  isDeductionModalOpen: boolean
}> = ({ isDeductionModalOpen }) => {
  const navigate = useNavigate()
  const openDeductionModal = () => navigate('add-deduction')
  const closeDeductionModal = () => navigate('..', { relative: 'path' })

  return (
    <>
      {isDeductionModalOpen && (
        <DeductionModal onRequestClose={closeDeductionModal} />
      )}
      <PayrollDashboardCard
        description={t('dashboard.deductions.description')}
        icon={<HealthBenefitsDisplayIcon accessibility='decorative' />}
        subtitle={t('dashboard.deductions.subtitle')}
        title={t('dashboard.deductions.title')}
        linkAction={{
          label: t('dashboard.deductions.link'),
          onClick: () => {
            trackDashboard('manual-deduction')
            openDeductionModal()
          }
        }}
      />
    </>
  )
}

/**
 * For Payroll Pro users who had the legacy benefits module
 * Allows continued access to the legacy benefits feature
 * Does not support SimplyInsured integration
 **/
const LegacyBenefitsNoSimplyInsuredCard: React.FunctionComponent = () => {
  const client = useUser().client
  const linkHref = useHref(`/mvc/${client}/Company/Benefits/Dashboard`)

  return (
    <PayrollDashboardCard
      description={t('dashboard.legacy-benefits.description.no-simply-insured')}
      icon={<EmployeesDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.legacy-benefits.subtitle')}
      title={t('dashboard.legacy-benefits.title')}
      linkAction={{
        label: t('dashboard.legacy-benefits.link'),
        href: linkHref,
        onClick: () => trackDashboard('legacy.view-existing')
      }}
    />
  )
}

/**
 * For Payroll Pro users who had the legacy benefits module
 * Allows continued access to the legacy benefits feature
 * and the ability to change the broker of record to SimplyInsured
 **/
const LegacyBenefitsCard: React.FunctionComponent = () => {
  const [isConsentOpen, setIsConsentOpen] = React.useState<boolean>(false)
  const openConsentModal = () => setIsConsentOpen(true)
  const closeConsentModal = () => setIsConsentOpen(false)

  const client = useUser().client
  const linkHref = useHref(`/mvc/${client}/Company/Benefits/Dashboard`)

  return (
    <>
      {isConsentOpen && (
        <ConsentModal
          insuranceFlow='broker-of-record'
          onRequestClose={closeConsentModal}
        />
      )}
      <PayrollDashboardCard
        description={t('dashboard.legacy-benefits.description')}
        icon={<EmployeesDisplayIcon accessibility='decorative' />}
        subtitle={t('dashboard.legacy-benefits.subtitle')}
        title={t('dashboard.legacy-benefits.title')}
        primaryAction={{
          label: t('dashboard.legacy-benefits.primary-button'),
          onClick: () => {
            trackDashboard('legacy.connect-existing')
            openConsentModal()
          }
        }}
        linkAction={{
          label: t('dashboard.legacy-benefits.link'),
          href: linkHref,
          onClick: () => trackDashboard('legacy.view-existing')
        }}
      />
    </>
  )
}

const VestwellRetirementPlanCard: React.FunctionComponent = () => {
  return (
    <PayrollDashboardCard
      description={t('dashboard.vestwell-retirement.description')}
      icon={<VestwellDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.vestwell-retirement.subtitle')}
      title={t('dashboard.vestwell-retirement.title')}
      linkAction={{
        label: t('dashboard.vestwell-retirement.link'),
        href: 'https://save.with.vestwell.com/toast-benefits-book-a-demo',
        newTab: true,
        icon: <LaunchIcon accessibility='decorative' />,
        onClick: () => trackDashboard('vestwell')
      }}
    />
  )
}

const PayrollProUpsellPanel: React.FunctionComponent = () => {
  return (
    <PayrollUpsellPanel
      illustration={
        <CommitmentAccountabilityIllustration
          className='h-60'
          resizeToContainer
        />
      }
      badgeLabel={t('dashboard.upsell.badge')}
      title={t('dashboard.upsell.title')}
      description={t('dashboard.upsell.description')}
      primaryAction={{
        label: t('dashboard.upsell.button.demo'),
        href: 'https://pos.toasttab.com/ca/request-demo',
        onClick: () => trackDashboard('payroll-pro-upsell.request-demo')
      }}
      secondaryAction={{
        label: t('dashboard.upsell.button.learn-more'),
        href: 'https://central.toasttab.com/s/article/Toast-Payroll-Manage-Benefits-With-SimplyInsured',
        onClick: () => trackDashboard('payroll-pro-upsell.learn-more')
      }}
    />
  )
}

const FootnoteSection: React.FunctionComponent = () => {
  const hasVestwellRetirementPlanOption =
    useCustomer().hasVestwellRetirementPlanOption
  const hasBenefitsBrokerageAdministration =
    useCustomer().hasBenefitsAdministration
  const hasEntitlementOverrideOption =
    useCustomer().hasEntitlementOverrideOption

  const footnotes = [
    ...(hasBenefitsBrokerageAdministration || hasEntitlementOverrideOption
      ? [t('dashboard.footnote.group-health')]
      : []),
    ...(hasVestwellRetirementPlanOption
      ? [t('dashboard.footnote.retirement-plan')]
      : [])
  ]

  return (
    <>
      <DividingLine noMargin />
      <div className='mx-4 md:mx-0'>
        {footnotes.map((footnote) => {
          return (
            <div key={footnote} className='type-caption text-gray-75'>
              {footnote}
            </div>
          )
        })}
      </div>
    </>
  )
}
