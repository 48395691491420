import * as React from 'react'
import {
  PayrollInstructionStepModal,
  StepProps
} from '@toasttab/buffet-pui-payroll-instruction-step-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DownloadIcon } from '@toasttab/buffet-pui-icons'
import { trackDeduction } from '@local/track'
import { t } from '@local/translations'

export type DeductionModalProps = {
  onRequestClose: VoidFunction
}

export const DeductionModal: React.FunctionComponent<DeductionModalProps> = ({
  onRequestClose
}) => {
  const steps: StepProps[] = [
    {
      title: t('deduction.step1.title'),
      description: t('deduction.step1.description'),
      button: (
        <Button
          as='a'
          href='https://d2c9w5yn32a2ju.cloudfront.net/knowledgebase/payroll/Deduction-Code-Request-Form_TPTM.pdf'
          iconRight={<DownloadIcon accessibility='decorative' />}
          onClick={() => trackDeduction('download')}
          rel='noreferrer'
          target='_blank'
          variant='text-link'
        >
          {t('deduction.step1.button')}
        </Button>
      )
    },
    {
      title: t('deduction.step2.title'),
      description: t('deduction.step2.description')
    },
    {
      title: t('deduction.step3.title'),
      description: t('deduction.step3.description'),
      button: (
        <Button
          as='a'
          id='payroll_chatbot'
          href='payrollsupport@toasttab.com' // fallback email in case javascript is not enabled
          onClick={() => trackDeduction('chat')}
          variant='secondary'
        >
          {t('deduction.step3.button')}
        </Button>
      )
    }
  ]

  const onClose = () => {
    trackDeduction('close')
    onRequestClose()
  }

  return (
    <PayrollInstructionStepModal
      title={t('deduction.title')}
      description={t('deduction.description')}
      isOpen={true}
      onClose={onClose}
      steps={steps}
    />
  )
}
