import 'systemjs-webpack-interop/auto-public-path'
// IMPORTANT: Do not move or remove the above import. It must be at the top of this file. See https://www.npmjs.com/package/systemjs-webpack-interop#newer-versions-of-webpack
import {
  banquetSingleSpaReact,
  BanquetProps,
  BanquetSingleSpaReact
} from 'banquet-runtime-modules'
import { domElementGetter } from '@toasttab/ec-layout'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

import { loadTranslations } from '@local/translations'
import { loadTracking } from '@local/track'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-employee-benefits-spa',
  rootComponent: App,
  domElementGetter: domElementGetter.application, // selects the correct mounting element for payroll
  sentry: {
    publicKey: '470ac3b71d9987e415f7c8d7e388e1a6@o37442',
    projectId: '4507810792996864',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap: BanquetSingleSpaReact['bootstrap'] = [
  loadTranslations,
  loadTracking,
  ...reactLifecycles.bootstrap
]
export const mount: BanquetSingleSpaReact['mount'] = reactLifecycles.mount
export const unmount: BanquetSingleSpaReact['unmount'] = reactLifecycles.unmount
export const name = 'employee-benefits-spa'
